import React, { useState } from 'react';
import './login.css';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const users = [
    { username: 'master', password: '123' },
    { username: 'Marcos', password: 'm123' },
    { username: 'Ellen', password: 'e123' },
    { username: 'Kilma', password: 'k123' },
  ];

  const handleLogin = () => {
    const user = users.find((user) => user.username === username && user.password === password);
    if (user) {
      onLogin(username); // Passe o nome de usuário para a função onLogin
    } else {
      alert('Usuário ou senha incorretos. Tente novamente.');
    }
  };

  return (
    <div className="login">
      <div className="login-box">
        <h2>LOGIN</h2>
        <input
          type="text"
          placeholder="Nome de usuário"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Entrar</button>
      </div>
    </div>
  );
}

export default Login;
