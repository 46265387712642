import React, { useState } from 'react';
import './App.css';
import Tela from './tela';
import Login from './Login';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  const handleLogin = (username) => {
    setIsLoggedIn(true);
    setUsername(username);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        {isLoggedIn ? (
          <div>
            <p>Usuário Logado: {username}</p>
            <button onClick={handleLogout}>Sair</button>
          </div>
        ) : (
          <Login onLogin={handleLogin} />
        )}
      </header>

      {/* Use o componente Tela somente se o usuário estiver logado e passe o 'username' como propriedade */}
      {isLoggedIn && <Tela username={username} />}
    </div>
  );
}

export default App;
