import React, { useEffect, useState, useRef } from 'react';
import './tela.css';
import axios from "axios"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { differenceInDays, parseISO } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import EditTodo from './comp/edit';




function App(props) {
  const [dados, setDados] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [dataProximaCobranca, setDataProximaCobranca] = useState('');
  const [faturasSelecionadas, setFaturasSelecionadas] = useState([]);
  const [statusSelecionado, setStatusSelecionado] = useState({});
  const [dataVencimento, setDataVencimento] = useState(null);
  const [dataCobrancaPorPedido, setDataCobrancaPorPedido] = useState({});
  const [dataProximaCobrancaPorPedido, setDataProximaCobrancaPorPedido] = useState({});
  const [observacoes, setObservacoes] = useState({});
  const [representanteSelecionado, setRepresentanteSelecionado] = useState('');
  const [todos, setTodos] = useState([]);
  const [dadosLinhaSelecionada, setDadosLinhaSelecionada] = useState(null);
  const [ filtro1, setFrilto1] = useState([])
  const [ filtro2, setFrilto2] = useState([])

  const [items, setItems] = useState([]);

  const [Yamato, setYamato] = React.useState(true)


  const [mostrarTabelaExistente, setMostrarTabelaExistente] = useState(false); // Estado para controlar a exibição da tabela existente
  const [mostrarTabelaGetTodos, setMostrarTabelaGetTodos] = useState(false); // Estado para controlar a exibição da tabela do getTodos






  const [ordenacao, setOrdenacao] = useState({
    coluna: null,
    direcao: 'asc',
  });


  const [filtroDataVencimento, setFiltroDataVencimento] = useState(null);

  const dadosOriginaisRef = useRef([]); 

   
  

  fetch(`http://localhost:3000/api/:items`)
  .then((response) => {
    if (!response.ok) {
      throw new Error('Erro ao carregar os dados');
    }
    return response.json();
  })
  .then((data) => {
    // Manipular os dados recebidos (data)
    console.log(data); // Exemplo: exibir os dados no console
  })
  .catch((error) => {
    console.error('Erro:', error);
  });





  const aplicarFiltroDataVencimento = () => {
    if (!filtroDataVencimento) {
      return;
    }

   
   



    const obterRepresentantesUnicos = (dados) => {
      const representantesSet = new Set();
      dados.forEach((item) => representantesSet.add(item.representante));
      return Array.from(representantesSet);
    };
    const representantesUnicos = obterRepresentantesUnicos(dadosValorTotalPorCliente);

    const dataAtual = new Date();
    const dadosFiltrados = dadosOriginaisRef.current.filter((item) => {
      const dataVencimento = parseISO(item.dt_vencimento);
      if (filtroDataVencimento === 'vencido') {
        return dataVencimento < dataAtual;
      } else if (filtroDataVencimento === 'A Vencer') {
        return dataVencimento >= dataAtual;
      }
      return true;
    });

    setDados(dadosFiltrados);
  };

  const alternarDirecaoOrdenacao = () => {
    const novaDirecao = ordenacao.direcao === 'asc' ? 'desc' : 'asc';
    setOrdenacao({ ...ordenacao, direcao: novaDirecao });
  };
  
  const calcularQuantidadeFaturasVencidas = (clienteID) => {
    const dataAtual = new Date();
    let quantidadeVencidas = 0;
  
    dados.forEach((item) => {
      if (item.cdclifaturai === clienteID) {
        const dataVencimento = new Date(item.dt_vencimento);
  
        if (dataVencimento < dataAtual) {
          quantidadeVencidas++;
        }
      }
    });
  
    return quantidadeVencidas;
  };

  
  const abrirObservacaoModal = (pedido) => {
    const observacaoAtual = observacoes[pedido] || "";
    const observacaoNova = prompt("Insira uma observação:", observacaoAtual);
    if (observacaoNova !== null) {
      setObservacoes({ ...observacoes, [pedido]: observacaoNova });
    }
  };
  
  const salvarObservacao = (pedido) => {
    const observacaoAtual = observacoes[pedido] || "";
    // Aqui, você pode enviar a observação para o servidor ou fazer o que for necessário
    console.log(`Observação para o pedido ${pedido}: ${observacaoAtual}`);
    alert("Observação salva com sucesso!");
  };
  

  const handleChangeStatus = (status, novoStatus) => {
    setStatusSelecionado((prevStatus) => ({
      ...prevStatus,
      [status]: novoStatus,
    }));
  }

const ordenarDados = (coluna, direcao) => {
  const copiaDados = [...dados];
  copiaDados.sort((a, b) => {
    if (coluna === 'dataVencimento') {
      const dataA = new Date(a.dt_vencimento);
      const dataB = new Date(b.dt_vencimento);
      if (direcao === 'asc') {
        return dataA - dataB;
      } else {
        return dataB - dataA;
      }
    } else if (coluna === 'pedido') {
      if (direcao === 'asc') {
        return a.pedidopedidoc2.localeCompare(b.pedidopedidoc2);
      } else {
        return b.pedidopedidoc2.localeCompare(a.pedidopedidoc2);
      }
    } else if (coluna === 'valor') {
      const valorA = parseFloat(a.vlfatura);
      const valorB = parseFloat(b.vlfatura);
      if (direcao === 'asc') {
        return valorA - valorB;
      } else {
        return valorB - valorA;
      }
    }
    return 0;
  });

  setDados(copiaDados);
};
const fecharModal1 = () => {
  setModalData(null);
  setObservacoes({}); // Limpar as observações
};

// Função para atualizar a data de cobrança por pedido
const atualizarDataCobranca = (dt_cobranca, novaData) => {
  setDataCobrancaPorPedido({
    ...dataCobrancaPorPedido,
    [dt_cobranca]: novaData,
  });
};

// Função para atualizar a data de próxima cobrança por pedido
const atualizarDataProximaCobranca = (prox_cob, novaData) => {
  setDataProximaCobrancaPorPedido({
    ...dataProximaCobrancaPorPedido,
    [prox_cob]: novaData,
  });
};


  const resetarFiltroDataVencimento = () => {
    setFiltroDataVencimento(null);
    setDados(dadosOriginaisRef.current); // Restaurar os dados originais
  };
  
  useEffect(() => {
    let apiUrl = 'http://localhost:3000/dados'; // URL padrão
    
  
    if (props.username === 'Ellen') {
      apiUrl = 'http://localhost:3000/ellen';
    } else if (props.username === 'Marcos') {
      apiUrl = 'http://localhost:3000/marcos';
    } else if (props.username === 'Kilma') {
      apiUrl = 'http://localhost:3000/kilma';
    }
  
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => setDados(data))
      .catch((error) => console.error('Erro ao buscar dados:', error));
  }, [props.username]);


  const handleFaturaSelecionada = (e, item) => {
    const isChecked = e.target.checked;
    setYamato(false)

    if (isChecked) {
      setFaturasSelecionadas((prevSelecionadas) => [...prevSelecionadas, item]);
    } else {
      setFaturasSelecionadas((prevSelecionadas) =>
        prevSelecionadas.filter((fatura) => fatura.id !== item.id)
      );
    }

    // Exibir os dados da linha no console
    console.log('Dados da linha:', item);
  };


  //function concatenarValores(nrfatfaturai, parcfaturai) {
    //return `${nrfatfaturai} - ${parcfaturai}`;
 // }  
  
  const calcularTotalFaturasPorRepresentante = () => {
    const totalFaturasPorRepresentante = {};

    dados.forEach((item) => {
      const representante = item.nmreppedidoc2;
      if (!totalFaturasPorRepresentante[representante]) {
        totalFaturasPorRepresentante[representante] = 0;
      }
      totalFaturasPorRepresentante[representante] += parseFloat(item.vlfatura);
    });

    return totalFaturasPorRepresentante;
  };

  const totalFaturasPorRepresentante = calcularTotalFaturasPorRepresentante();


  

  const calcularDiferencaDias = (dataVencimentoISO) => {
    if (!dataVencimentoISO) {
      return '';
    }

    const dataVencimento = parseISO(dataVencimentoISO);
    const dataAtual = new Date();
    const diferenca = differenceInDays(dataVencimento, dataAtual);

    if (diferenca > 0) {
      return `Faltam ${diferenca}`;
    } else if (diferenca < 0) {
      return `Atrasado ${Math.abs(diferenca)}`;
    } else {
      return 'Hoje é o dia do vencimento';
    }
  };

 

  
  const calcularValorTotalPorCliente = (dados) => {
    const valorTotalPorCliente = {};

    dados.forEach((item) => {
      const clienteID = item.cdclifaturai;

      if (!valorTotalPorCliente[clienteID]) {
        valorTotalPorCliente[clienteID] = {
          id: clienteID,
          nome: item.nmclipedidoc2,
          representante: item.nmreppedidoc2,
          total: 0,
        };
      }

      valorTotalPorCliente[clienteID].total += parseFloat(item.vlfatura);
    });

    return Object.values(valorTotalPorCliente);
  };


  const getClassForDiasAtraso = (dataVencimentoISO) => {
    if (!dataVencimentoISO) {
      return ''; // Classe vazia para datas sem valor
    }
  
    const dataVencimento = parseISO(dataVencimentoISO);
    const dataAtual = new Date();
    const diferenca = differenceInDays(dataVencimento, dataAtual);
  
    if (diferenca < 0) {
      return 'vencido'; // Classe para datas vencidas
    } else {
      return 'nao-vencido'; // Classe para datas que não estão vencidas
    }
  };


  const formatarDataCurta = (dataISO) => {
    const data = new Date(dataISO);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const dadosValorTotalPorCliente = calcularValorTotalPorCliente(dados);

  const calcularTotalPorTransacao = (dados) => {
    const totalPorTransacao = {};

    dados.forEach((item) => {
      const transacao = item.nrtrapedidotra;

      if (!totalPorTransacao[transacao]) {
        totalPorTransacao[transacao] = 0;
      }

      totalPorTransacao[transacao] += parseFloat(item.vlfatura);
    });

    return totalPorTransacao;
  };

  const totalPorTransacao = calcularTotalPorTransacao(dados);

  const abrirModal = (clienteID) => {
    const cliente = dados.find((item) => item.cdclifaturai === clienteID);
    setModalData(cliente);
  };

  const fecharModal = () => {
    setModalData(null);
  };

  const encontrarDataProximaVencimento = (clienteID) => {
    const dataAtual = new Date();
    let dataProxima = null;
  
    dados.forEach((item) => {
      if (item.cdclifaturai === clienteID) {
        const dataVencimento = new Date(item.dt_vencimento);
  
        if (!dataProxima || dataVencimento < dataProxima) {
          dataProxima = dataVencimento;
        }
      }
    });
  
    return dataProxima;
  };
  
  const linkLikeButtonStyle = {
    textDecoration: "underline",
    cursor: "pointer",
    color: "black",
  };


  const contarPedidosDistintos = (clienteID) => {
    const pedidosUnicos = new Set();

    dados.forEach((item) => {
      if (item.cdclifaturai === clienteID) {
        pedidosUnicos.add(item.pedidopedidoc2);
      }
    });

    return pedidosUnicos.size;
  };


  const getClassForDataProximaCobranca = (dataProximaCobrancaISO) => {
    if (!dataProximaCobrancaISO) {
      return ''; // Classe vazia para datas em branco
    }
  
    const dataProximaCobranca = parseISO(dataProximaCobrancaISO);
    const dataAtual = new Date();
  
    if (dataProximaCobranca < dataAtual) {
      return 'data-atrasada'; // Classe para datas anteriores à data atual (vermelho)
    } else {
      return 'data-futura'; // Classe para datas futuras à data atual (verde)
    }
  }


  const calcularValorVencido = (clienteID) => {
    const dataAtual = new Date();
    let valorVencido = 0;

    dados.forEach((item) => {
      if (item.cdclifaturai === clienteID) {
        const dataVencimento = new Date(item.dt_vencimento);

        if (dataVencimento < dataAtual) {
          valorVencido += parseFloat(item.vlfatura);
        }
      }
    });

    return valorVencido;
  };
  const ordenarPorFaturasVencidas = () => {
    // Clone os dados para não afetar o estado original
    const dadosClonados = [...dados];
  
    dadosClonados.sort((a, b) => {
      const faturasA = calcularQuantidadeFaturasVencidas(a.cdclifaturai);
      const faturasB = calcularQuantidadeFaturasVencidas(b.cdclifaturai);
  
      if (ordenacao.coluna === 'faturasVencidas') {
        // Verifique a direção da ordenação
        if (ordenacao.direcao === 'asc') {
          return faturasA - faturasB;
        } else {
          return faturasB - faturasA;
        }
      }
      return 0;
    });
  
    // Atualize o estado com os dados reordenados e a nova direção da ordenação
    setDados(dadosClonados);
    alternarDirecaoOrdenacao();
  };

  const dadosAgrupadosPorRepresentante = {};

  // Inicialize os grupos com arrays vazios
  dadosValorTotalPorCliente.forEach((item) => {
    const representante = item.representante;
  
    if (!dadosAgrupadosPorRepresentante[representante]) {
      dadosAgrupadosPorRepresentante[representante] = [];
    }
  
    dadosAgrupadosPorRepresentante[representante].push(item);
  });
  

  const dadosAgrupados = {};

dados.forEach((item) => {
  const representante = item.representante;
  if (!dadosAgrupados[representante]) {
    dadosAgrupados[representante] = {
      representante: item.representante,
      total: 0,
      pedidos: 0,
    };
  }
  dadosAgrupados[representante].total += item.total || 0;
  dadosAgrupados[representante].pedidos += 1;
});
const obterRepresentantesUnicos = (dados) => {
  const representantes = new Set();
  dados.forEach((item) => {
    representantes.add(item.representante);
  });
  return Array.from(representantes);
};


const representantesUnicos = obterRepresentantesUnicos(dadosValorTotalPorCliente);
  

const calcularTotalValorVencido = (representante) => {
  const valorVencidoPorRepresentante = dados
    .filter((item) => item.nmreppedidoc2 === representante)
    .reduce((total, item) => {
      const dataVencimento = new Date(item.dt_vencimento);
      const dataAtual = new Date();
      if (dataVencimento < dataAtual) {
        return total + parseFloat(item.vlfatura);
      }
      return total;
    }, 0);

  return valorVencidoPorRepresentante;
};

const dataCobrancaFormatada = Object.keys(dataCobrancaPorPedido).reduce((acc, key) => {
  acc[key] = dataCobrancaPorPedido[key].toISOString().split('T')[0];
  return acc;
}, {});

const dataProximaCobrancaFormatada = Object.keys(dataProximaCobrancaPorPedido).reduce((acc, key) => {
  acc[key] = dataProximaCobrancaPorPedido[key].toISOString().split('T')[0];
  return acc;
}, {});


const enviarDadosParaBanco = async () => {
  try {
    // Verifica se há itens selecionados antes de enviar para o backend
    if (faturasSelecionadas.length === 0) {
      console.log('Nenhum item selecionado para enviar.');
      return;
    }

   

    const dadosParaEnviar = faturasSelecionadas.map((item) => ({
      pedidopedidoc2: item.pedidopedidoc2,
      nrtrapedidotra: item.nrtrapedidotra,
      operacao: item.operacao,
      nrfatfaturai: item.nrfatfaturai,
      parcfaturai: item.parcfaturai,
      dttrapedidotra : item.dttrapedidotra,
      tipopedido : item.tipopedido,
      nr_portador : item.nr_portador,
      dt_vencimento : item.dt_vencimento,
      status_pd : statusSelecionado['1'],
      observacao : observacoes['1'],
      data_cobranca : dataCobrancaFormatada['1'],
      prox_cob : dataProximaCobrancaFormatada['1'],
      vlfatura : item.vlfatura

      // Adicione outras colunas aqui conforme necessário
    }));

    console.log(filtro1)
    console.log('Dados para enviar ao backend:', dadosParaEnviar);

    const response = await fetch('http://localhost:3000/inserirDados', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dadosParaEnviar) ,
    });

    if (response.ok) {
      console.log('Dados enviados com sucesso:', response);
    } else {
      console.error('Erro ao enviar dados para o banco de dados.');
    }
  } catch (error) {
    console.error('Erro:', error.message);
  }
};


const getTodos = async () => {
  try {
    const response = await fetch(`http://localhost:3000/todos`);

    
    const jsonData = await response.json();

    // Atualizar o estado com os dados obtidos
    
    setTodos(jsonData);
    console.log(todos)
  } catch (err) {
    console.error(err.message);
  }
};

// Chamada para buscar os dados de /todos quando o componente montar
useEffect(() => {
  getTodos();
}, []);











  return (

    
    <div className="App">
     <Select
  options={representantesUnicos.map((representante) => ({
    value: representante,
    label: representante,
  }))}
  onChange={(selectedOption) => setRepresentanteSelecionado(selectedOption.value)}
  placeholder="Filtrar por Representante"
/>
      <header className="App-header">
        
<div className="App">
      <header className="App-header">
        {/* Conteúdo da Tela */}
        <h1>Bem-vindo, {props.username}!</h1>
      
      </header>

<div>
  <h2>Totalizadores por Representante</h2>
  <table>
    <thead>
      <tr>
        <th>Representante</th>
        <th>Total Aberto</th>
        <th>Valor Vencido</th>
        <th>Total Pedidos</th>
      </tr>
    </thead>
    <tbody>
      {representantesUnicos.map((representante) => (
        <tr key={representante}>
          <td>{representante}</td>
          <td>
            {totalFaturasPorRepresentante[representante]
              ? totalFaturasPorRepresentante[representante].toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              : '0'}
          </td>
          <td>
            {calcularTotalValorVencido(representante).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })}
          </td>
          <td>{dadosAgrupadosPorRepresentante[representante]?.length || 0}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
    </div>
          
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>NOME</th>
              <th>REPRESENTANTE</th>
              <th>VALOR ABERTO</th>
              <th>DATA VENCIMENTO</th>
              <th>DATA PROX COBRANÇA</th>
              <th>PEDIDOS</th>
              <th>VALOR VENCIDO</th>
<th
  
>
  FATURAS VENCIDAS
</th>
              
            </tr>
          </thead>
          <tbody>
          {dadosValorTotalPorCliente
    .filter((item) => !representanteSelecionado || item.representante === representanteSelecionado)
    .map((item) => (
              <tr key={item.id} onClick={() => abrirModal(item.id)}>
                <td>{item.id}</td>
                <td>{item.nome}</td>
                <td>{item.representante}</td>
                <td>{item.total ? item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                <td>{formatarDataCurta(encontrarDataProximaVencimento(item.id))}</td>
                <td className={getClassForDataProximaCobranca(dataProximaCobranca)}>
  {dataProximaCobranca ? formatarDataCurta(dataProximaCobranca) : ''}
</td>

                <td>{contarPedidosDistintos(item.id)}</td>
                <td>{calcularValorVencido(item.id).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                <td>{calcularQuantidadeFaturasVencidas(item.id)}</td> 
              </tr>
            ))}
          </tbody>
        </table>
      </header>
      {modalData && (
        <div className="modal-overlay">
          <button onClick={fecharModal}>X</button>
          
      {/* Adicione os elementos de filtro para a data de vencimento aqui */}
      <div className="filtro-container">
        <label>Filtrar por Data de Vencimento:</label>
        <Select
          options={[
            { value: 'vencido', label: 'Vencido' },
            { value: 'A Vencer', label: 'A Vencer' },
          ]}
          value={{ value: filtroDataVencimento, label: filtroDataVencimento ? (filtroDataVencimento === 'vencido' ? 'Vencido' : 'A Vencer') : 'Selecione' }}
          onChange={(selectedOption) => setFiltroDataVencimento(selectedOption.value)}
        />
        <button onClick={aplicarFiltroDataVencimento}>Aplicar Filtro</button>
        <button onClick={resetarFiltroDataVencimento}>Limpar Filtro</button>
      </div>
   
      <div className="dados-cliente" style={{ display: 'flex', alignItems: 'center' }}>
  <div className="cliente-info" style={{ padding: '2px', borderRadius: '8px', color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <h2 style={{ margin: '0', borderBottom: '1px solid #ffa726', paddingBottom: '10px' }}>Dados do Cliente</h2>
    <div className="cliente-info" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <p style={{ margin: '5px' }}><strong>Cliente:</strong> {modalData.nmclipedidoc2}</p>
      <p style={{ margin: '5px' }}><strong>Telefone:</strong> {modalData.telefone}</p>
      <p style={{ margin: '5px' }}><strong>Email:</strong> {modalData.email}</p>
      <p style={{ margin: '5px' }}><strong>Endereço:</strong> {modalData.endereco}</p>
    </div>
  </div>
</div>



          <div className="dados-cliente">
            <div className="cliente-info">
            <div className="table-container" style={{ maxHeight: '690px', overflowY: 'auto', backgroundColor: 'white', padding: '10px', borderRadius: '8px', margin: '10' }}>
                <h2 style={{ margin: '0', borderBottom: '1px solid #ffa726', paddingBottom: '15px', marginBottom: '10px' }}>DETALHAMENTO</h2>
                <button onClick={enviarDadosParaBanco}>Salvar</button>
                <table>
                
                  <thead>
                    <tr>
                      <th>SELECT</th>
                      <th
  onClick={() => {
    ordenarDados('pedido', ordenacao.direcao);
    alternarDirecaoOrdenacao();
  }}
  className={`header-cell ${ordenacao.coluna === 'pedido' ? `ordenado-${ordenacao.direcao}` : ''}`}
>
  PEDIDO
</th>
                      <th className="sticky-th">TRANSAÇÃO</th>
                      <th className="sticky-th">OPERAÇÃO</th>
                      <th className="sticky-th">
                       DT.TRA
                      </th>
                      <th className="sticky-th">TIPO PEDIDO</th>
                      <th className="sticky-th">TRANSAÇÃO(R$)</th>
                      <th className="sticky-th">FATURA</th>
                      <th
  onClick={() => {
    ordenarDados('valor', ordenacao.direcao);
    alternarDirecaoOrdenacao();
  }}
  className={`header-cell ${ordenacao.coluna === 'valor' ? `ordenado-${ordenacao.direcao}` : ''}`}
>
  VALOR
</th>
                      <th>PORTADOR</th>
                      <th
  onClick={() => {
    ordenarDados('dataVencimento', ordenacao.direcao);
    alternarDirecaoOrdenacao();
  }}
  className={`header-cell ${ordenacao.coluna === 'dataVencimento' ? `ordenado-${ordenacao.direcao}` : ''}`}
>
  DT.VENC
</th>
                      <th className="sticky-th">DIAS</th>
                      <th>DOCUMENTO</th>
                      <th>COBRADOR</th>
                      <th>STATUS</th>
                      <th>OBSERVAÇÃO</th>
                      <th>DT.COBRANÇA</th>
                      <th>PROX.COB</th>

                    </tr>
                  </thead>
                  <tbody>
                    {dados
                      .filter((item) => item.cdclifaturai === modalData.cdclifaturai)
                      .map((item) => (
                        <tr key={item.id}>
                          <td>
                          <input
                          type="checkbox"
                          onChange={(e) => handleFaturaSelecionada(e, item)}
                           checked={faturasSelecionadas.includes(item)}
                              />
                          </td>
                          <td data-tip={item.pedidopedidoc2} data-for={`tooltip-${item.id}`}>
                            {item.pedidopedidoc2}
                          </td>
                          <td>{item.nrtrapedidotra}</td>
                          <td>{item.operacao.length > 6 ? `${item.operacao.slice(0, 6)}...` : item.operacao}</td> 
                          <td data-tip={formatarDataCurta(item.dttrapedidotra)} data-for={`tooltip-${item.id}`}>
                            {formatarDataCurta(item.dttrapedidotra)}
                          </td>

                          <td>{item.tipopedido.length > 20 ? `${item.tipopedido.slice(0, 20)}...` : item.tipopedido}</td> 
                       
                          <td>
                            {totalPorTransacao[item.nrtrapedidotra].toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          <td>{item.nrfatfaturai}  -   {item.parcfaturai}</td>
                          <td>
                            {item.vlfatura.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          <td>
                            {item.nr_portador}
                          </td>
                          <td>
                            {formatarDataCurta(item.dt_vencimento)}
                          </td>

                          <td className={getClassForDiasAtraso(item.dt_vencimento)}>
                  {calcularDiferencaDias(item.dt_vencimento)}
                </td>
          

<td>
  {(() => {
    switch (item.tpdocfaturai) {
      case '1':
        return 'BOLETO';
      case '7':
        return 'CHEQUE';
      case '3':
        return 'A VISTA';
      default:
        return 'Outro'; // Valor padrão se não corresponder a nenhum caso
    }
  })()}
</td>




                          <td>MASTER</td>
                          <td>
                          <select
  value={statusSelecionado[item.parcfaturai] || ''} // Valor padrão: uma string vazia
  onChange={(e) => handleChangeStatus(item.parcfaturai, e.target.value)}
>
  <option value="">Selecione</option> {/* Opção em branco */}
  <option value="concluido">Concluído</option>
  <option value="retorna">Retorna</option>
  <option value="naoAtendeu">Não Atendeu</option>
</select>
            
            
    </td>


              <td>
      
              <span
    style={linkLikeButtonStyle}
    onClick={() => abrirObservacaoModal(item.parcfaturai)}
    title={observacoes[item.parcfaturai] || ""}
  >
    {observacoes[item.parcfaturai]
      ? observacoes[item.parcfaturai].length > 6
        ? observacoes[item.parcfaturai].slice(0, 6) + "..."
        : observacoes[item.parcfaturai]
      : "Adicionar"}
  </span>

      </td>


  


<td>
        <DatePicker
          selected={dataCobrancaPorPedido[item.parcfaturai]}
          onChange={(date) => atualizarDataCobranca(item.parcfaturai, date)}
          dateFormat="dd/MM/yyyy"
          placeholderText="Selecione uma data"
        />
      </td>
      <td>
        <DatePicker
          selected={dataProximaCobrancaPorPedido[item.parcfaturai]}
          onChange={(date) => atualizarDataProximaCobranca(item.parcfaturai, date)}
          dateFormat="dd/MM/yyyy"
          placeholderText="Selecione uma data"
        />
      </td>
      



                        </tr>
                        
                      ))}
                  </tbody>
                </table>

                    
                      <h2 hidden={Yamato} className='cobrancaseeft'>COBRANÇAS EFETUADAS</h2>
                <table hidden={Yamato} className="table1 table-bordered table-light text-center  table-rounded">
      <thead>
        <tr>
          <th>Pedido</th>
          <th>FATURA</th>
          <th>PARCELA</th>
          <th>DT.COBRANÇA</th>
          <th>PROX.COB</th>
          <th>STATUS</th>
          <th>OBSERVAÇÃO</th>
          
        </tr>
      </thead>
      <tbody>
        {todos.map(todo => (
          <tr key={todo.id}>
            <td>{todo.pedidopedidoc2}</td>
            <td>{todo.nrfatfaturai}</td>
            <td>{todo.parcfaturai}</td>
            <td>{todo.data_cobranca}</td>
            <td>{todo.prox_cob}</td>
            <td>{todo.status_pd}</td>
            <td>{todo.observacao}</td>

             {/* Valor da coluna "Status" */}
            </tr>
          ))}
        </tbody>
      </table>

               
                

                
              </div>
            </div>
          </div>
        </div>
      )}

     

      

      


        


    </div>
    
  );
}

export default App;